import request from '@/utils/request'
import {a} from "@fullcalendar/core/internal-common";


export const addMedicalProduct: any = (data: any) => {
    console.log(data)
    return request({
        url: '/medicalProduct',
        method: 'post',
        data
    })
}

export const updateMedicalProduct: any = (data: any) => {
    console.log(data)
    return request({
        url: '/medicalProduct',
        method: 'put',
        data
    })
}
export const deleteMedicalProduct: any = (id: string) => {
    console.log(id)
    return request({
        url: `/medicalProduct/${id}`,
        method: 'delete',
    })
}
export const getById: any = (id: string) => {
    console.log(id)
    return request({
        url: `/medicalProduct/${id}`,
        method: 'get',
    })
}
export const pageListMedicalProduct: any = (data: a) => {
    console.log(data)
    return request({
        url: `/medicalProduct/pageList`,
        method: 'post',
        data
    })
}